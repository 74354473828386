.slideUp {
  animation: slideUpAnimation 0.5s ease-in-out;
}
.slideOut {
  animation: slideOutAnimation 0.5s ease-in-out forwards;
}
.btnClr {
  background-color: transparent !important;
  color: transparent !important;
}
.submitBtn {
  background-color: #20929d !important;
}
.expand {
  height: 70vh;
  transition: height 0.5s ease;
  overflow: hidden;
}
.half {
  height: 30vh;
  transition: height 0.5s ease;
  overflow: hidden;
}
.expandMap {
  height: 57vh;
  transition: height 0.5s ease;
  overflow: hidden;
}
.halfMap {
  height: 17vh;
  transition: height 0.5s ease;
  overflow: hidden;
}
.up {
  transform: rotate(0deg);
  transition: transform 0.5s ease;
}
.down {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}
@keyframes slideUpAnimation {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideOutAnimation {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}
