.ball {
  animation: bounce 0.5s forwards;
}
.ballout {
  animation: slideOutFrame 0.5s forwards;
}
.smallSize {
  max-width: 60%;
  margin-top: 70px;
  transition: max-width 0.3s ease, margin-top 0.5s ease;
  overflow: hidden;
}
.normalSize {
  max-width: 100%;
  padding-top: 5px;
  margin-top: 0;
  transition: max-width 1s ease, margin-top 0.5s ease;
  overflow: hidden;
}
@keyframes bounce {
  0% {
    transform: translateY(80px);
  }
  40% {
    transform: translateY(-20px);
  }
  70% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideOutFrame {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-200%);
  }
}
