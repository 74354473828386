#merks {
  display: grid;
  place-items: center;
  border-color: transparent !important;
}
#merks::-webkit-scrollbar {
  display: none;
}
.dMpiWb:focus,
.gVufvl:focus {
  outline: 0 !important;
}
[role="option"] > span {
  display: none !important;
}
[role="option"] > p {
  padding: 0 !important;
  margin: 0 !important;
}
/* .sc-beyTiQ > span {
  display: none !important;
} */
/* .sc-beyTiQ > p {
  padding: 0 !important;
  margin: 0 !important;
} */
.gpTRCZ {
  justify-content: center !important;
}
.submitBtn {
  background-color: #20929d !important;
}
#backCircle {
  background-color: #10494e;
}
.custom-wheel-picker-container {
  position: relative;
  overflow: hidden;
  height: 200px;
}
.custom-wheel-picker-overlay-bot,
.custom-wheel-picker-overlay-top {
  position: absolute;
  left: 0;
  width: 100%;
  height: 15%;
  pointer-events: none;
  z-index: 2;
}
.custom-wheel-picker-overlay-top {
  top: 0;
  background: linear-gradient(to bottom, #10494e, rgba(16, 73, 78, 0));
}
.custom-wheel-picker-overlay-bot {
  bottom: 0;
  background: linear-gradient(to top, #10494e, rgba(16, 73, 78, 0));
}
.circle {
  position: fixed !important;
  top: 80%;
  left: 50%;
  width: 680px;
  height: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden !important;
}
.roll-out {
  animation: 0.3s forwards slideup;
}
@keyframes slideup {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-200%);
  }
}
