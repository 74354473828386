.btnShare {
  background-color: #21565a !important;
}
.slideIn {
  animation: slideUpAnim 0.5s ease-in-out;
}
.slideInTop {
  animation: slideInAnim 0.5s ease forwards;
}
.slideRight {
  animation: slideRightAnim 1s ease-in-out forwards;
}
.backForthAnim {
  animation: animateBg 2.5s ease-in-out infinite;
  background-image: linear-gradient(90deg, #fff, #238f9998, #fff);
  background-size: 400% 100%;
}
.backForthAnim2 {
  animation: animateBg 2.5s ease-in-out infinite !important;
}
.wave {
  display: inline-block;
  overflow: hidden;
  animation: wave 1s linear infinite;
}
.wave1 {
  display: inline-block;
  overflow: hidden;
  animation: wave 1s linear infinite;
  animation-delay: 0.2s;
}
.wave2 {
  display: inline-block;
  overflow: hidden;
  animation: wave 1s linear infinite;
  animation-delay: 0.4s;
}
.resultClass::-webkit-scrollbar {
  width: 5px;
  color: transparent !important;
}
.resultClass::-webkit-scrollbar-track {
  background: #21565a;
}
.resultClass::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #b5dbde99;
}
@keyframes animateBg {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 70% 0;
  }
  100% {
    background-position: 0 0;
  }
}
@keyframes slideUpAnim {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideRightAnim {
  0% {
    transform: translateX(-100%);
    opacity: 1;
  }
  50% {
    transform: translateX(30px);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInAnim {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes wave {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(8px);
  }
}
