.slideUp {
  animation: slideUpAnimation 0.5s ease-in-out;
}
.slideOut {
  animation: slideOutAnimation 0.5s ease-in-out forwards;
}
.up {
  transform: rotate(0deg);
  transition: transform 0.5s ease;
}
.down {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}
@keyframes slideUpAnimation {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideOutAnimation {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}
