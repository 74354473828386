@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
body {
  font-family: "Poppins" !important;
}
.App {
  font-family: "Poppins";
  background-color: #1b7a83 !important;
}
.cardContainer {
  position: relative;
  width: 80%;
  height: 40vh;
}
.cards {
  position: absolute !important;
  width: 100%;
  height: 100%;
  background-color: #3498db;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: right center;
}
#card3 {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
#card2 {
  animation: rotateAnimation2 1.5s ease forwards;
  background-color: #b5dbde;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
#card1 {
  animation: rotateAnimation3 1.5s ease forwards;
  background-color: #1b7a83;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}
.nextButton {
  background-color: #1b7a83 !important;
}
.icon {
  transition: color 0.3s ease;
}
.slide-right {
  animation: slideInRight 0.5s forwards;
}
.reverse-slide-right {
  animation: slideInRightReverse 0.5s forwards;
}
.slide-left {
  animation: slideOutLeft 0.5s forwards;
}
.reverse-slide-left {
  animation: slideOutLeftReverse 0.5s forwards;
}
.slider-container {
  overflow-x: hidden;
}
.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}
.slide {
  flex: 0 0 100%;
  width: 100%;
}
.fadeout {
  animation: fadingout 0.5s forwards;
}
.fadein {
  animation: fadingin 1s forwards;
}
button, li {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scaleButton {
  animation: scaling 1s forwards;
  background-color: #1b7a83;
  color: #ffffff;
  position: fixed;
  right: 40px;
  bottom: 19px;
  border-radius: 30px;
  display: block;
  width: 48px;
  height: 40px;
  animation-delay: 0.5s;
  transform-origin: bottom right;
}
@keyframes scaling {
  0% {
    border-radius: 30px;
  }
  100% {
    width: 100%;
    height: 100vh;
    bottom: 0px;
    right: 0px;
  }
}
@keyframes fadingin {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadingout {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes rotateAnimation2 {
  from {
    transform: rotate(0deg) translateX(5%);
  }
  to {
    transform: rotate(10deg) translateX(10%) translateY(5%);
  }
}
@keyframes rotateAnimation3 {
  from {
    transform: rotate(0deg) translateX(10%);
  }
  to {
    transform: rotate(20deg) translateX(15%) translateY(10%);
  }
}
@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInRightReverse {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}
@keyframes slideOutLeftReverse {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
